import React, { useCallback, useContext, useEffect, useState } from "react";
import companyLogo from "../../assets/img/company-logo.svg";
import OrderDetails from "../../components/OrderDetails";
import GlobalContext from "../../contexts/GlobalContext";
import _ from "lodash";
import TrackingDetails from "../../components/TrackingDetails";
import { Redirect } from "react-router-dom";
import {
    ROUTE_NAME_LOGIN,
    UI_STATE_EMPTY,
    UI_STATE_FAILURE,
    UI_STATE_LOADING,
    UI_STATE_SUCCESS,
} from "../../static/strings";
import StatefulUi from "../../components/stateful-ui/stateful-ui";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import {
    getDeliveryInformationForTrackingCode,
    getDeliveryInformation,
    getRiderLocation,
    getDeliveryInformationForCdrId,
    getTrackingConfigs,
} from "../../services/api.service";
import "./homepage.scss";
import pidgeLogo from "../../assets/img/pidge-logo.svg";

const NewHomePage = (props) => {
    const query = useLocationQuery();
    const globalStore = useContext(GlobalContext);
    const [riderId, setRiderId] = useState("");
    const [rider_data, set_rider_data] = useState(null);
    const [trackCode, setTrackCode] = useState(query.get("t"));
    const [cdr_id, set_cdr_id] = useState(query.get("id"));
    const [deliveryInformation, setDeliveryInformation] = useState(null);
    const [viewState, setViewState] = useState(UI_STATE_LOADING);
    const [stateText, setStateText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [configs, set_configs] = useState({ top_banner: {} });
    const [support, set_support] = useState([]);

    useEffect(() => {
        trackCode && fetchDeliveryInformationUsingTrackCode();
        cdr_id && fetchDeliveryInformationUsingCdrId();
        props?.user?.phone && props?.user?.awb && fetchDeliveryInformation();
        if (trackCode) {
            get_configs_from_tracking();
        }
    }, [props, trackCode, cdr_id]);

    const fetchDeliveryInformation = () => {
        setViewState(UI_STATE_LOADING);
        getDeliveryInformation(
            props?.user?.phone || globalStore.phone,
            props?.user?.awb || globalStore.awb
        )
            .then(onGetDeliveryInformationSuccess)
            .catch(onGetDeliveryInformationError);
    };

    const fetchDeliveryInformationUsingTrackCode = useCallback(() => {
        setViewState(UI_STATE_LOADING);
        getDeliveryInformationForTrackingCode(trackCode)
            .then(onGetDeliveryInformationSuccess)
            .catch(onGetDeliveryInformationError);
    }, [trackCode]);

    const fetchDeliveryInformationUsingCdrId = useCallback(() => {
        setViewState(UI_STATE_LOADING);
        getDeliveryInformationForCdrId(cdr_id)
            .then(onGetDeliveryInformationSuccess)
            .catch(onGetDeliveryInformationError);
    }, [cdr_id]);

    const onGetDeliveryInformationSuccess = (res) => {
        if (res.data.data) {
            if (res.data.data) {
                setDeliveryInformation(
                    JSON.parse(JSON.stringify(res.data.data))
                );
                setRiderId(_.get(res.data.data, "id", ""));
                setViewState(UI_STATE_SUCCESS);
            } else {
                setViewState(UI_STATE_EMPTY);
            }
        } else {
            setViewState(UI_STATE_FAILURE);
        }
    };

    const onGetDeliveryInformationError = (err) => {
        setViewState(UI_STATE_FAILURE);
        setStateText(err?.response?.data?.message);
    };

    useEffect(() => {
        if (
            _.has(deliveryInformation, "fulfillment") &&
            _.has(deliveryInformation, "id", "")
        ) {
            setRiderId(_.get(deliveryInformation, "id", ""));
        }
    }, [deliveryInformation]);

    useEffect(() => {
        if (riderId.length) {
            fetchRiderLocationUsingId();
        }
    }, [riderId]);

    const fetchRiderLocationUsingId = useCallback(() => {
        setViewState(UI_STATE_LOADING);
        getRiderLocation(riderId)
            .then(onGetRiderInformationSuccess)
            .catch(onGetRiderInformationError);
    }, [riderId]);

    const refreshRiderLocationUsingId = async (riderId, auto_refresh) => {
        setIsLoading(true);
        try {
            const res = await getRiderLocation(riderId);
            if (res.status === 200) {
                if (!_.isEmpty(res)) {
                    set_rider_data(JSON.parse(JSON.stringify(res.data.data)));
                    setIsLoading(false);
                    if (!auto_refresh) {
                        setButtonDisabled(true);
                        setTimeout(() => setButtonDisabled(false), 30000);
                    }
                } else {
                    setIsLoading(false);
                    set_rider_data(null);
                    if (!auto_refresh) {
                        setButtonDisabled(true);
                        setTimeout(() => setButtonDisabled(false), 30000);
                    }
                    setViewState(UI_STATE_FAILURE);
                }
            }
        } catch {
            setIsLoading(false);
            set_rider_data(null);
            if (!auto_refresh) {
                setButtonDisabled(true);
                setTimeout(() => setButtonDisabled(false), 30000);
            }
        }
    };

    const getTrackingDetails = async () => {
        try {
            const res = trackCode
                ? await getDeliveryInformationForTrackingCode(trackCode)
                : await getDeliveryInformationForCdrId(cdr_id);
            if (res.data.data) {
                if (res.data.data) {
                    setDeliveryInformation(
                        JSON.parse(JSON.stringify(res.data.data))
                    );
                    setRiderId(_.get(res.data.data, "id", ""));
                    setViewState(UI_STATE_SUCCESS);
                } else {
                    setViewState(UI_STATE_EMPTY);
                }
            } else {
                setViewState(UI_STATE_FAILURE);
            }
        } catch {
            setViewState(UI_STATE_FAILURE);
        }
    };

    const onGetRiderInformationSuccess = (res) => {
        if (res.status === 200) {
            if (!_.isEmpty(res)) {
                set_rider_data(res.data.data);
                setViewState(UI_STATE_SUCCESS);
            } else {
                set_rider_data(null);
                setViewState(UI_STATE_SUCCESS);
            }
        } else {
            set_rider_data(null);
            setViewState(UI_STATE_FAILURE);
        }
    };

    const onGetRiderInformationError = (err) => {
        setViewState(UI_STATE_SUCCESS);
        // commenting this because we do not want to throw error case in case of rider location failed
        // setViewState(UI_STATE_FAILURE);
        // setStateText(err?.response?.data?.message);
    };

    const get_configs_from_tracking = async () => {
        const res = await getTrackingConfigs(trackCode);
        let _configs = _.get(
            res.data,
            "data.business.whitelabeling.tracking",
            {}
        );
        delete _configs.is_editable;
        if (!_configs.marketing_content) {
            delete _configs.marketing_content;
        }
        if (!_.isEmpty(_configs)) set_configs(_configs);
        else {
            set_configs(
                _.get(res.data, "data.parent.whitelabeling.tracking", {})
            );
        }

        if (res.data.data?.network?.support) {
            const filtered = _.filter(
                res.data.data?.network?.support,
                (item) => item.type === "consumer"
            );
            set_support(filtered);
        }
    };

    if (!(trackCode || cdr_id) && !props?.user?.phone && !props?.user?.awb) {
        return <Redirect to={`/${ROUTE_NAME_LOGIN}`} />;
    }

    const BANNER_CONTENT_LIMIT = window.innerWidth < 768 ? 60 : 230;

    return (
        <div style={{ marginBottom: "100px" }}>
            {!_.isEmpty(configs.top_banner) && (
                <>
                    <div
                        className="top-bar"
                        style={{
                            backgroundColor: configs.top_banner.color,
                        }}
                    >
                        {configs.top_banner.content.length <
                            BANNER_CONTENT_LIMIT && (
                            <h5
                                style={{
                                    margin: 0,
                                    color: configs.top_banner.text_color,
                                }}
                            >
                                {configs.top_banner.content}
                            </h5>
                        )}
                        {configs.top_banner.content.length >=
                            BANNER_CONTENT_LIMIT && (
                            <marquee scrollAmount={4}>
                                <h5
                                    style={{
                                        margin: 0,
                                        color: configs.top_banner.text_color,
                                    }}
                                >
                                    {configs.top_banner.content}
                                </h5>
                            </marquee>
                        )}
                    </div>
                </>
            )}
            <div className="content" style={{ marginTop: 40 }}>
                <StatefulUi currentState={viewState} stateText={stateText}>
                    <div
                        className="space-between-container"
                        style={{ alignItems: "end" }}
                    >
                        <div>
                            {configs.business_logo && (
                                <div
                                    onClick={() => {
                                        if (configs.website_link)
                                            window.open(
                                                configs.website_link,
                                                "_blank",
                                                "noreferrer"
                                            );
                                    }}
                                    style={{
                                        cursor: configs.website_link
                                            ? "pointer"
                                            : "default",
                                    }}
                                >
                                    <img
                                        src={configs.business_logo}
                                        alt="company-logo"
                                        style={{ width: "auto", height: 60 }}
                                    />
                                </div>
                            )}
                            {_.get(deliveryInformation, "owner.name", "") &&
                                !configs.business_logo && (
                                    <>
                                        <h2 className="brand-heading">
                                            Your order from
                                        </h2>
                                        <h1 className="brand-name-information">
                                            {_.get(
                                                deliveryInformation,
                                                "owner.name",
                                                ""
                                            )}
                                        </h1>
                                    </>
                                )}
                        </div>
                        <div>
                            <p className="powered-pidge d-flex">
                                <div className="powered-info-margin">
                                    Powered by{" "}
                                </div>
                                <div>
                                    <img
                                        src={pidgeLogo}
                                        alt="company logo"
                                        height={20}
                                    />
                                </div>
                            </p>
                        </div>
                    </div>
                    {deliveryInformation && (
                        <div
                            className="p-grid track-section-alignment"
                            style={{
                                flexDirection: `${
                                    window.innerWidth < 767
                                        ? "column-reverse"
                                        : ""
                                }`,
                            }}
                        >
                            <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                                <OrderDetails
                                    deliveryInformation={deliveryInformation}
                                    getTrackingDetails={getTrackingDetails}
                                    configs={configs}
                                    support={support}
                                />
                            </div>
                            <div className="p-col-12 p-md-12 p-lg-6 p-xl-6">
                                <TrackingDetails
                                    deliveryInformation={deliveryInformation}
                                    rider_data={rider_data}
                                    riderId={riderId}
                                    refreshRiderLocationUsingId={
                                        refreshRiderLocationUsingId
                                    }
                                    isLoading={isLoading}
                                    buttonDisabled={buttonDisabled}
                                    setButtonDisabled={setButtonDisabled}
                                    configs={configs}
                                />
                            </div>
                        </div>
                    )}
                </StatefulUi>
            </div>
        </div>
    );
};

export default NewHomePage;
