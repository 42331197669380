export const orderStatusInformation = (status) => {
    switch (status) {
        case "CREATED":
            return "Order Confirmed";

        case "OUT_FOR_PICKUP":
            return "Out for pickup";

        case "PICKED_UP":
            return "Dispatched";

        case "REACHED_PICKUP":
            return "Reached Pickup";

        case "REACHED_DELIVERY":
            return "Reached Drop";

        case "IN_TRANSIT":
            return "In Transit";

        case "OUT_FOR_DELIVERY":
            return "Out for delivery";

        case "RTO_OUT_FOR_DELIVERY":
            return "Return to Origin";

        case "RTO_UNDELIVERED":
            return "Return to Origin";

        case "RTO_DELIVERED":
            return "Return to Origin";

        case "LOST":
            return "Return to Origin";

        case "DAMAGED":
            return "Return to Origin";

        case "DELIVERED":
            return "Delivered";

        default:
            return "Undelivered";
    }
};

export const trip_final_status = [
    "DELIVERED",
    "UNDELIVERED",
    "RTO_OUT_FOR_DELIVERY",
    "RTO_UNDELIVERED",
    "RTO_DELIVERED",
    "LOST",
    "DAMAGED",
];

export const trip_initial_status = [
    "CREATED",
    "OUT_FOR_PICKUP",
    "PICKED_UP",
    "IN_TRANSIT",
    "OUT_FOR_DELIVERY",
];

export const trip_between_status = [
    "PICKED_UP",
    "IN_TRANSIT",
    "OUT_FOR_DELIVERY",
    "RTO_OUT_FOR_DELIVERY",
    "REACHED_DELIVERY",
];
