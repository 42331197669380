import React from "react";
import ImageLinks from "../../services/ImageLinks";
import { makeStyles } from "@mui/styles";
import Text from "../common/Text";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import theme from "../../services/theme";
import web_bg from "../../assets/img/footer_bg_web.png";
import mobile_bg from "../../assets/img/footer_bg_mobile.png";

const useStyles = makeStyles(() => ({
    container_wrap: {
        padding: "25px 60px 25px 60px",
        backgroundColor: theme.colors.lightPink4,
        marginTop: "auto",
        width: "100vw",
        "@media (max-width: 900px)": {
            padding: 10,
            backgroundColor: theme.colors.lightPink4,
        },
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 900px)": {
            alignItems: "center",
            textAlign: "center",
            gap: 30,
        },
    },
    icons_flex: {
        display: "flex",
        width: "100%",
        gap: 5,
        "@media (max-width: 900px)": {
            maxWidth: 230,
            margin: "auto",
            justifyContent: "center",
            gap: 15,
        },
    },
    icon_wrap: {
        border: "2px solid white",
        borderRadius: "50%",
        cursor: "pointer",
        height: 32,
        width: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: {
        color: theme.colors.primary,
        height: "12px !important",
        width: "12px !important",
    },
    link_options: {
        display: "flex",
        alignItems: "center",
        gap: 100,
        "@media (min-width: 1100px)": {
            marginLeft: -200,
        },
    },
    link_text: {
        cursor: "pointer",
        color: theme.colors.lightPurple5,
        fontSize: 14,
        "@media (max-width: 900px)": {
            fontSize: 8,
        },
    },
    powered_text: {
        fontSize: 18,
        "@media (max-width: 900px)": {
            fontSize: 10,
        },
    },
    rights_text: {
        "@media (max-width: 900px)": {
            display: "none",
        },
    },
    rights_text_2: {
        display: "none",
        "@media (max-width: 900px)": {
            display: "block",
            marginTop: 20,
        },
    },
    logo: {
        cursor: "pointer",
        marginLeft: 5,
        height: 18,
        "@media (max-width: 900px)": {
            height: 10,
        },
    },
}));

function Footer() {
    const classes = useStyles();
    return (
        <div className={classes.container_wrap}>
            <div className={classes.container}>
                <div className="d-flex align-items-center">
                    <Text semi className={classes.powered_text}>
                        Powered by
                    </Text>
                    <img
                        src={ImageLinks.pidge_logo}
                        width={"auto"}
                        className={classes.logo}
                        onClick={() => {
                            window.open("https://pidge.in/", "_blank");
                        }}
                    />
                </div>
                <div className={classes.link_options}>
                    <div className="d-flex" style={{ gap: 20 }}>
                        <Text
                            semi
                            className={classes.link_text}
                            onClick={() => {
                                window.open(
                                    "https://pidge.in/privacy-policy/",
                                    "_blank"
                                );
                            }}
                        >
                            Privacy Policy
                        </Text>
                        <Text
                            semi
                            className={classes.link_text}
                            onClick={() => {
                                window.open(
                                    "https://pidge.in/terms-of-use/",
                                    "_blank"
                                );
                            }}
                        >
                            Terms of Use
                        </Text>
                    </div>

                    <Text
                        semi
                        className={classes.rights_text}
                        style={{ fontSize: 12, textAlign: "center" }}
                    >
                        © 2023 Pidge | All Rights Reserve
                    </Text>
                </div>
                <div>
                    <div className={classes.icons_flex}>
                        <div
                            className={classes.icon_wrap}
                            onClick={() => {
                                window.open(
                                    "https://www.linkedin.com/company/pidge-mobility/mycompany/",
                                    "_blank"
                                );
                            }}
                        >
                            <LinkedInIcon className={classes.icon} />
                        </div>
                        <div
                            className={classes.icon_wrap}
                            onClick={() => {
                                window.open(
                                    "https://www.instagram.com/pidge_mobility/",
                                    "_blank"
                                );
                            }}
                        >
                            <InstagramIcon className={classes.icon} />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Text
                    semi
                    className={classes.rights_text_2}
                    style={{ fontSize: 12, textAlign: "center" }}
                >
                    © 2023 Pidge | All Rights Reserve
                </Text>
            </div>
        </div>
    );
}

export default Footer;
